import { motion } from "framer-motion";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

//Assets
import pendingApproval from "../../images/Icon.png";
import SandboxLogo from "../../images/icons/sandbox-logo.svg";
import ingenico from "../../images/brands/ingenico-white.png";
import pax from "../../images/brands/pax.png";
import verifone from "../../images/brands/verifone.png";
import sunmi from "../../images/brands/sunmi.png";
import urovo from "../../images/brands/urovo.png";

///Components
import AccountStatusComponent from "./AccountStatusComponent";
import SignupForm from "./SignupForm";

//Styles
import * as styles from "../../styles/components/SandboxComponents.module.css";

// Utils
import { Toaster } from "react-hot-toast";
import Layout from "../../components/Layout";
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import clsx from "clsx";

const Signup = ({ hasNavbarOnlyWhite }) => {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = React.useState(false);
  const [loginPending, setLoginPending] = React.useState(false);

  const pendingApprovalStatus = loginPending && (
    <AccountStatusComponent
      image={pendingApproval}
      title={t("sandbox.signup.pending.approval.title")}
      description={t("sandbox.signup.pending.approval.description")}
    />
  );

  const DescribeWhoWeAre = () => {
    return (
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`sectionContainer`}
      >
        <motion.div
          variants={revealVariant}
          className="flex flex-col sm:max-xl:items-center items-start gap-16"
        >
          <Link to="/sandbox">
            <SandboxLogo className={`${styles.heroSection__logo}`} />
          </Link>
          <div className="flex flex-col gap-6 sm:max-xl:text-center text-start">
            <h2 className={styles.heroSection__title}>
              {emailSent
                ? t("sandbox.signup.emailSent.title")
                : t("sandbox.signup.title")}
            </h2>
            <h3 className={styles.heroSection__description}>
              {emailSent
                ? t("sandbox.signup.emailSent.description")
                : t("sandbox.signup.description")}
            </h3>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  const PartnersSection = () => {
    const brands = [
      { id: "ingenico", img: ingenico },
      { id: "pax", img: pax },
      { id: "verifone", img: verifone },
      { id: "sunmi", img: sunmi },
      { id: "urovo", img: urovo },
    ];
    return (
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`sectionContainer sm:max-xl:text-center text-start`}
      >
        <motion.h2 className={styles.partnerSection__title}>
          {t("sandbox.signup.brands")}
        </motion.h2>
        <motion.div
          variants={revealVariant}
          className={`${styles.partnerSection__container} sm:max-xl:justify-center justify-start`}
        >
          {brands.map((brand) => (
            <motion.img
              className={styles.partnerSection__img}
              variants={revealVariant}
              src={brand.img}
              alt={brand.id}
              key={brand.id}
            />
          ))}
        </motion.div>
      </motion.div>
    );
  };

  return (
    <Layout>
      <section
        className={`${styles.section__wrapper} ${
          emailSent ? "!pb-[68px]" : "!pb-[185px]"
        }`}
      >
        <motion.div
          {...revealContainerWithChildrenProps}
          className={`flex flex-col items-center xl:items-start xl:flex-row xl:justify-center gap-16 `}
        >
          <div
            className={clsx([
              "flex flex-col gap-20 max-w-[600px]",
              "items-center",
              "xl:max-w-[550px]",
            ])}
          >
            <DescribeWhoWeAre />
            {/* <PartnersSection /> */}
          </div>
          <div className="mx-6 lg:mx-0">
            <SignupForm setEmailSent={setEmailSent} emailSent={emailSent} />
          </div>
          <Toaster />
        </motion.div>
        {/*
      {pendingApprovalStatus} 
      */}
      </section>
    </Layout>
  );
};

export default Signup;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "sandbox"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
